import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { MiniArrowLeftIcon, SearchIcon } from "../../../../common/assets/svg";
import {
  HeaderContainer,
  HeaderSubContainer,
  HeaderLeft,
  HeaderRight,
  BackButton,
  VehicleNumber,
  SearchInputContainer,
  SearchInput,
  TabContainer,
  Tab,
} from "./style";
import { VEHICLE_DETAILS } from "../../../../common/constants";

const Header = ({
  vehicleNumber,
  vehicleDetailsTab,
  setVehicleDetailsTab,
  violationDetailsTab,
  setViolationDetailsTab,
  searchText,
  handleSubmit,
  handleSearchTextChange,
  setSearchText,
}) => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderSubContainer>
        <HeaderLeft>
          <BackButton onClick={() => navigate(`/${VEHICLE_DETAILS}`)}>
            <MiniArrowLeftIcon />
          </BackButton>
          <VehicleNumber>{vehicleNumber}</VehicleNumber>
        </HeaderLeft>
        <HeaderRight>
          <form onSubmit={handleSubmit}>
            <SearchInputContainer>
              <SearchInput
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={handleSearchTextChange}
              />
              <span className="search-icon">
                <SearchIcon />
              </span>
              {searchText && (
                <span
                  className="clear-icon"
                  onClick={() => setSearchText("")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setSearchText("");
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  &#10005;
                </span>
              )}
            </SearchInputContainer>
          </form>
        </HeaderRight>
      </HeaderSubContainer>
      <TabContainer>
        <Tab
          onClick={() => {
            setVehicleDetailsTab(true);
            setViolationDetailsTab(false);
          }}
          style={{
            color: vehicleDetailsTab ? "#F58706" : "#333333",
            borderBottom: vehicleDetailsTab ? "2px solid #F58706" : "none",
          }}
        >
          Vehicle Details
        </Tab>
        <Tab
          onClick={() => {
            setVehicleDetailsTab(false);
            setViolationDetailsTab(true);
          }}
          style={{
            color: violationDetailsTab ? "#F58706" : "#333333",
            borderBottom: violationDetailsTab ? "2px solid #F58706" : "none",
          }}
        >
          Violation Details
        </Tab>
      </TabContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  vehicleNumber: PropTypes.string.isRequired,
  vehicleDetailsTab: PropTypes.bool.isRequired,
  setVehicleDetailsTab: PropTypes.func.isRequired,
  violationDetailsTab: PropTypes.bool.isRequired,
  setViolationDetailsTab: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  handleSearchTextChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
};
export default Header;
